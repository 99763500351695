/* eslint-disable no-unused-vars */
import React from 'react';
import routes, { unauthenticatedRoutes } from './routes';
import _ from 'lodash';
import authProvider from './components/auth_provider';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import useAuthProviderStore from './stores/auth_provider.store';
import AuthenticationProgressLoader from './components/progress_bar';

function App() {
  const { userAccount } = authProvider();
  const { userPermissions } = useAuthProviderStore((state) => ({
    userPermissions: state.userPermissions,
  }));

  const processRoutes = () => {
    if (userPermissions) {
      return _.isEmpty(userPermissions) ? unauthenticatedRoutes : routes;
    }
    return [];
  };
  const routing = useRoutes(processRoutes());

  return (
    <ThemeProvider theme={theme}>
      {_.isEmpty(userPermissions) ? (
        <AuthenticationProgressLoader label="Authenticating" />
      ) : (
        routing
      )}
    </ThemeProvider>
  );
}

export default App;
